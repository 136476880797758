export const HTTP_STATUS_CODES = {
  '100': $localize`Continue`,
  '101': $localize`Switching Protocols`,
  '102': $localize`Processing`,
  '103': $localize`Early Hints`,
  '200': $localize`OK`,
  '201': $localize`Created`,
  '202': $localize`Accepted`,
  '203': $localize`Non Authoritative Information`,
  '204': $localize`No Content`,
  '205': $localize`Reset Content`,
  '206': $localize`Partial Content`,
  '207': $localize`Multi-Status`,
  '208': $localize`Already Reported`,
  '226': $localize`IM Used`,
  '300': $localize`Multiple Choices`,
  '301': $localize`Moved Permanently`,
  '302': $localize`Moved Temporarily`,
  '303': $localize`See Other`,
  '304': $localize`Not Modified`,
  '305': $localize`Use Proxy`,
  '307': $localize`Temporary Redirect`,
  '308': $localize`Permanent Redirect`,
  '400': $localize`Bad Request`,
  '401': $localize`Unauthorized`,
  '402': $localize`Payment Required`,
  '403': $localize`Forbidden`,
  '404': $localize`Not Found`,
  '405': $localize`Method Not Allowed`,
  '406': $localize`Not Acceptable`,
  '407': $localize`Proxy Authentication Required`,
  '408': $localize`Request Timeout`,
  '409': $localize`Conflict`,
  '410': $localize`Gone`,
  '411': $localize`Length Required`,
  '412': $localize`Precondition Failed`,
  '413': $localize`Request Entity Too Large`,
  '414': $localize`Request-URI Too Long`,
  '415': $localize`Unsupported Media Type`,
  '416': $localize`Requested Range Not Satisfiable`,
  '417': $localize`Expectation Failed`,
  '418': $localize`I'm a teapot`,
  '419': $localize`Insufficient Space on Resource`,
  '420': $localize`Method Failure`,
  '421': $localize`Misdirected Request`,
  '422': $localize`Unprocessable Entity`,
  '423': $localize`Locked`,
  '424': $localize`Failed Dependency`,
  '425': $localize`Too Early`,
  '426': $localize`Upgrade Required`,
  '428': $localize`Precondition Required`,
  '429': $localize`Too Many Requests`,
  '431': $localize`Request Header Fields Too Large`,
  '451': $localize`Unavailable for Legal Reasons`,
  '500': $localize`Internal Server Error`,
  '501': $localize`Not Implemented`,
  '502': $localize`Bad Gateway`,
  '503': $localize`Service Unavailable`,
  '504': $localize`Gateway Timeout`,
  '505': $localize`HTTP Version Not Supported`,
  '506': $localize`Variant Also Negotiates`,
  '507': $localize`Insufficient Storage`,
  '508': $localize`Loop Detected`,
  '509': $localize`Bandwidth Limit Exceeded`,
  '510': $localize`Not Extended`,
  '511': $localize`Network Authentication Required`,
} as const;

export type HTTPStatusCode = keyof typeof HTTP_STATUS_CODES;

export const UNKNOWN_STATUS_CODE_TEXT = $localize`Unknown HTTP status code`;

export function getHttpStatusText(code: number | string, defaultText = UNKNOWN_STATUS_CODE_TEXT): string {
  return HTTP_STATUS_CODES.hasOwnProperty(code) ? HTTP_STATUS_CODES[code] : defaultText;
}

export function isSuccessfulStatusCode(code?: string | null): boolean {
  return Boolean(code?.startsWith('2'));
}

export function isErrorStatusCode(code?: string | null): boolean {
  return ['4', '5'].some(errorCode => code?.startsWith(errorCode));
}
