import {FormulaEvaluationError} from '../formula-interpreter/formula-interpreter.service';
import {DynamicConditionEvaluationError} from '../condition/dynamic-condition';

import {LcapEventHandlerLoggedError} from './lcap-event-handler';

export abstract class AbstractLcapLoggerService {
  abstract handleError(
    error: FormulaEvaluationError | DynamicConditionEvaluationError | LcapEventHandlerLoggedError,
  ): Error | void;
}
