import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveEnd} from '@angular/router';
import {filter} from 'rxjs/operators';

import {subscriptions} from './subscriptions';
import {MixpanelRouteName, MixpanelService} from './mixpanel';

@Injectable({
  providedIn: 'root',
})
export class RootMixpanelService extends MixpanelService {
  private subs = subscriptions();

  private _routeName: MixpanelRouteName = '';

  override get routeName(): MixpanelRouteName {
    return this._routeName;
  }

  initRouteTracking() {
    this.subs.add(
      this.router.events.pipe(filter(event => event instanceof ResolveEnd)).subscribe((event: ResolveEnd) => {
        let route: ActivatedRouteSnapshot | undefined = event.state.root;

        this._routeName = '';

        do {
          if (route.data.mixpanelRouteName) {
            this._routeName = route.data.mixpanelRouteName;
          }

          route = route.children.find(child => child.outlet === 'primary');
        } while (route);
      }),
    );
  }
}
