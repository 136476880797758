import {Injectable} from '@angular/core';
import {BehaviorSubject, EMPTY, Observable, Subject} from 'rxjs';
import {catchError, mergeMap, tap, throttleTime} from 'rxjs/operators';

import {subscriptions} from '@shared/services/subscriptions';
import {Request, getRequestAsObservable} from '@shared/services/http-resource';
import {FormulaEvaluationError} from '@shared/modules/formula-interpreter/formula-interpreter.service';
import {AbstractLcapLoggerService} from '@shared/modules/lcap/abstract-lcap-logger.service';
import {DynamicConditionEvaluationError} from '@shared/modules/condition/dynamic-condition';
import {LcapEventHandlerLoggedError} from '@shared/modules/lcap/lcap-event-handler';

import {HttpResource} from './http-resource';
import {PortalApp, PortalAppsService} from './portal-apps.service';
import {AppPage, AppPagesService} from './app-pages.service';
import {AppPublicPage, PublicAccessService} from './public-access.service';

export type PageError = FormulaEvaluationError | DynamicConditionEvaluationError | LcapEventHandlerLoggedError;
export type PageErrorDebugInfo = NonNullable<PageError['debugInfo']>;
export type PageErrorKey = string;
export interface PageErrorData {
  appSlug: PortalApp['slug'];
  message: string;
  debugInfo: PageErrorDebugInfo;
  isPublic: boolean;
  // `pageId` is optional because we don't have it in Public Access for now,
  // should be added later with https://workato.atlassian.net/browse/APPS-1475
  pageId: AppPage['id'] | null;
  accessToken: AppPublicPage['id'] | null;
}

export const ERROR_THROTTLE_INTERVAL = 60_000;

@Injectable()
export class LcapLoggerService extends AbstractLcapLoggerService {
  protected errors = new Subject<PageError>();
  protected errorStreams = new Map<PageErrorKey, BehaviorSubject<PageErrorData>>();
  protected errorsReporter$: Observable<void>;

  protected resource = new HttpResource({
    url: '/portal/api/apps/{{appSlug}}/logs.json',
  });

  protected publicResource = new HttpResource({
    url: '/portal/api/anonymous/pages/{{accessToken}}/logs.json',
  });

  private subs = subscriptions();

  constructor(
    private appsService: PortalAppsService,
    private pagesService: AppPagesService,
    private publicAccessService: PublicAccessService,
  ) {
    super();

    this.errorsReporter$ = this.getErrorsReporterObservable();
    this.subs.add(this.errorsReporter$.subscribe());
  }

  override handleError(error: PageError): LcapHandledBuilderError {
    this.errors.next(error);

    return new LcapHandledBuilderError('Error in user formula/condition/event handler');
  }

  sendPageError(
    {appSlug, pageId, message, debugInfo, isPublic, accessToken}: PageErrorData,
    eventsCount = 1,
  ): Request<void> {
    const resource = isPublic ? this.publicResource : this.resource;
    const pathParams = isPublic ? {accessToken} : {appSlug};

    return resource.post(pathParams, {
      page_id: pageId,
      data: {
        message,
        debug_info: debugInfo,
        events_count: eventsCount,
        is_public_page: isPublic,
      },
    });
  }

  private getErrorsReporterObservable(): Observable<void> {
    return this.errors.pipe(
      mergeMap(err => {
        const isPublic = this.publicAccessService.enabled;
        const appSlug = isPublic ? this.publicAccessService.appSlug : this.appsService.currentApp?.slug;
        const pageId = isPublic ? null : (this.pagesService.currentPage?.id ?? null);
        const accessToken = isPublic ? this.publicAccessService.accessToken : null;
        const debugInfo = err.debugInfo;

        if (!appSlug || !debugInfo || !(pageId || accessToken)) {
          return EMPTY;
        }

        const errorData: PageErrorData = {
          message: `An error has occurred on the page: "${err.message}"`,
          appSlug,
          pageId,
          accessToken,
          debugInfo,
          isPublic,
        };
        const key = JSON.stringify(errorData);
        const errorStream = this.errorStreams.get(key);

        // Emit to existing stream if it's present
        if (errorStream) {
          errorStream.next(errorData);

          return EMPTY;
        }

        // Otherwise, make throttled stream
        const newErrorStream = new BehaviorSubject<PageErrorData>(errorData);

        this.errorStreams.set(key, newErrorStream);

        return this.getThrottledErrorObservable(newErrorStream);
      }),
    );
  }

  private getThrottledErrorObservable(errorStream: BehaviorSubject<PageErrorData>): Observable<void> {
    let count = 0;

    return errorStream.pipe(
      tap(() => count++),
      throttleTime(ERROR_THROTTLE_INTERVAL, undefined, {leading: true, trailing: true}),
      mergeMap(errorData => {
        const errorsCount = count;

        count = 0;

        return this.getSendErrorObservable(errorData, errorsCount);
      }),
    );
  }

  private getSendErrorObservable(data: PageErrorData, eventsCount?: number): Observable<void> {
    return getRequestAsObservable(this.sendPageError(data, eventsCount)).pipe(catchError(() => EMPTY));
  }
}

export class LcapHandledBuilderError extends Error {
  override name = 'LcapHandledBuilderError';
}
