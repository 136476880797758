<div
  *ngIf="hasBackground"
  #overlayBackground
  class="overlay-background"
  [class.overlay-background_opaque]="isMobileView"
  (click)="close()"
></div>
<div
  #overlayContent
  class="overlay {{ opts.class }}"
  [ngClass]="{
    overlay_mobile: isMobileView,
    overlay_resizable: fitStrategy === 'resize',
    overlay_absolute: isAbsolutelyPositioned,
  }"
  [style.z-index]="opts.zIndex"
  [wThemingContext]="opts.themingContext ?? 'inherit'"
  [wElementResizeThrottleTime]="1"
  [wElementResizeSkipFirstEvent]="true"
  (wElementResize)="position()"
>
  <ng-template #contentContainer />
</div>
