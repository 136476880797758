import {isPlainObject} from 'lodash';

interface VerticalCoords {
  top: number;
  bottom: number;
}

interface ScrollOptions {
  animate?: boolean;
}

interface ScrollIntoViewOptions extends ScrollOptions {
  margin?: number;
  stickySelectors?: string[];
}

interface ScrollToPositionOptions extends ScrollOptions {
  top?: number;
  left?: number;
}

export function getRootScrollable(): Element {
  return document.querySelector('.app-layout__content') ?? document.documentElement;
}

export function scrollViewportTo(options: ScrollToPositionOptions) {
  scrollElementTo(getRootScrollable(), options);
}

export function scrollElementTo(element: Element, {top, left, animate}: ScrollToPositionOptions) {
  scrollElement(element, top ?? element.scrollTop, left ?? element.scrollLeft, Boolean(animate));
}

export function scrollElementIntoView(element: Element, opts?: ScrollIntoViewOptions): void;
export function scrollElementIntoView(container: Element, element?: Element | null, opts?: ScrollIntoViewOptions): void;

export function scrollElementIntoView(
  container: Element,
  element?: Element | ScrollIntoViewOptions | null,
  options?: ScrollIntoViewOptions,
) {
  if (arguments.length < 2 || isPlainObject(element)) {
    options = {...(element as ScrollIntoViewOptions)};
    options.stickySelectors = ['.app-layout__banners', ...(options.stickySelectors ?? [])];
    scrollWithinContainer(getRootScrollable(), container, options);
  } else if (element) {
    scrollWithinContainer(container, element as Element, options);
  }
}

function scrollWithinContainer(container: Element, element: Element, options?: ScrollIntoViewOptions) {
  const elementRect = element.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();
  const stickyElementBottomEdges = (options?.stickySelectors ?? []).map(
    selector => container.querySelector(selector)?.getBoundingClientRect().bottom ?? 0,
  );
  const scrollDelta = getScrollDelta(
    {
      top: Math.max(containerRect.top, ...stickyElementBottomEdges),
      bottom: containerRect.bottom,
    },
    elementRect,
    options,
  );

  if (scrollDelta) {
    scrollElement(container, container.scrollTop + scrollDelta, container.scrollLeft, Boolean(options?.animate));
  }
}

function scrollElement(element: Element, top: number, left: number, smooth: boolean) {
  if (!TEST && smooth && 'scrollBehavior' in document.documentElement.style) {
    element.scrollTo({
      left,
      top,
      behavior: 'smooth',
    });
  } else {
    element.scrollTop = top;
    element.scrollLeft = left;
  }
}

function getScrollDelta(container: VerticalCoords, element: VerticalCoords, opts: ScrollIntoViewOptions = {}): number {
  let margin = opts.margin || 0;
  let scrollDelta = 0;

  if (element.top < container.top) {
    scrollDelta = element.top - container.top;
    // Margin should increase negative value
    margin *= -1;
  } else if (element.bottom > container.bottom) {
    const bottomDelta = element.bottom - container.bottom;
    const topDelta = element.top - container.top;

    // Handles the case when element's height is greater than container's height
    if (topDelta < bottomDelta) {
      scrollDelta = topDelta;
      margin *= -1;
    } else {
      scrollDelta = bottomDelta;
    }
  }

  if (scrollDelta) {
    return scrollDelta + margin;
  } else {
    return scrollDelta;
  }
}
